import './style.css';
import LogoX from '../../assets/logo_nexhub_x.png';

const Loading = () => {

    return(
        <div className='container_loading'>
            <img className='logo_X' src={LogoX} alt='logo_nexhub_x'/>
        </div>
    )
}

export default Loading