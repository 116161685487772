import { createContext, useRef } from "react";

export const NexhubContext = createContext([]);

export const NexhubProvider = ({ children }) => {

    const buttonFooter = useRef(null);
    const buttonSobre = useRef(null);
    const buttonBeneficios = useRef(null);
    const buttonCasesDeSucesso = useRef(null);

    const buttonSobreMobile = useRef(null)
    const buttonBeneficiosMobile = useRef(null)
    const buttonCasesMobile = useRef(null)

    const buttonFooterUpBeneficios = useRef(null);
    const buttonFooterUpCases = useRef(null);

    const handleScrollToUp = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };
    const handleScrollToMouse = () => {
        buttonFooter.current.scrollIntoView({ behavior: 'smooth' });
      };
  
    const handleScrollToSobre = () => {
        buttonSobre.current.scrollIntoView({behavior: 'smooth'});
      };

    const handleScrollToCases = () => {
        buttonCasesDeSucesso.current.scrollIntoView({ behavior: 'smooth' });
      };
  
    const handleScrollToBeneficios = () => {
        buttonBeneficios.current.scrollIntoView({ behavior: 'smooth' });
      };

      const scrollToTargetBeneficios = () => {
        if (buttonFooterUpBeneficios.current) {
            buttonFooterUpBeneficios.current.scrollIntoView({ behavior: 'smooth' });
        }
      };
  
      const scrollToTargetCases = () => {
        if (buttonFooterUpCases.current) {
            buttonFooterUpCases.current.scrollIntoView({ behavior: 'smooth' });
        }
      };

      const whatsAppApi = () => {
        window.location.href = 'https://api.whatsapp.com/send?phone=5511945215604&text=Ol%C3%A1,%20Quero%20aumentar%20minhas%20vendas%20e%20gostaria%20de%20conhe%C3%A7er%20as%20solu%C3%A7%C3%B5es%20da%20Nexhub';
      }
  



  return (
    <NexhubContext.Provider value={{buttonFooter, buttonSobre, buttonBeneficios, 
    buttonCasesDeSucesso, buttonSobreMobile, buttonBeneficiosMobile,buttonCasesMobile,buttonFooterUpBeneficios,buttonFooterUpCases, 
    handleScrollToUp, handleScrollToMouse, handleScrollToSobre, handleScrollToCases, handleScrollToBeneficios, scrollToTargetBeneficios, 
    scrollToTargetCases, whatsAppApi}}>
        {children}
    </NexhubContext.Provider>
  );
};